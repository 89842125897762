/* ---vivi--- */
/* Splashpage */
.vivi .splash button {
  background-color: #558a87;
  color:#fff;
}

.vivi .splash {
  color: black;
}

/*  */

/* Menupage  fork->menu */


.vivi .tab {
  background: #abbe9e;
  border-color: #abbe9e;
  color: white;
}

.vivi .tab-active {
  background: #558a87;
  border-color: #558a87;
  color: white;
}

.vivi .arrow-trigger {
  background: #558a87;
  color: white;
  
}

.vivi .cta {
  background: #5bb888;
  color: black;
}


.vivi .filter-modal-trigger {
  background: #AA9468;
  color: black;
}

.vivi .subscription {
  background: #AA9468;
  color: black;
}

.vivi .share {
  background: #558a87;
  color: white;
}

.vivi .add-cart-item {
  background: #5bb888;
  color: black;
}

.vivi .allergens-modal-trigger span {
  background: #558a87;
  color: white;
}

.vivi .cta-no-orders {
  background: #AA9468;
  color: black;
}

.vivi .list {
  color: black;
  background: rgba(255, 255, 255, 0);
  margin-top: 0;
}

/*.vivi .even {
  color: black;
  background: transparent;
}

.vivi .odd {
  color: black;
  background: #abbe9e;
}*/

.vivi .powered-by {
  color: #558a87;
}

/*.vivi .dish>div {
  color: black;
  background: rgba(255, 255, 255, 0.9);
}

.vivi .drink>div {
  color: black;
  background: rgba(255, 255, 255, 0.9);
}

.vivi .drink-modal-trigger {
  color: black;
}*/

/*.vivi .drinks {
  background-color: #abbe9e;
}*/

.vivi .drinks.even {
  background-color: #558a87;
}

.vivi .drinks.odd {
  background-color: #abbe9e;
}

.vivi .products.even {
  background-color: #558a87;
}

.vivi .products.odd {
  background-color: #abbe9e;
}

.vivi .drink > div {
  background-color: white;
  border: solid 0.5px #abbe9e;
}

.vivi .product > div {
  background: white;
  border: solid 0px white;
}

.vivi .trigger.even {
  background-color: rgba(85, 138, 135, 0.9);
  color: white;
}

.vivi .trigger.odd {
  background-color: rgba(171, 190, 158, 0.8);
  color: black;
}

.vivi .ingredients span {
  color: black;
}

.vivi .vote.block {
  background-color: #5bb888;
}

/*  */
